@import 'app/style/utility/utility';
@import 'app/style/utility/variables';
@import 'app/style/mixins';
@import 'app/style/form';
@import 'app/style/sticky-header';


/* Scrollbar */

/* Width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #cacaca;
  border-radius: 10px;
}

body {
  margin: 0;
  &:before {
    background-image: url('./assets/solar2.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    content: " ";
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: .9;
    z-index: -1;
  }
}

.cdk-overlay-backdrop {
  z-index: 0 !important;
}

button:disabled {
  cursor: not-allowed !important;
  pointer-events: auto;
  background-color: #d8dadc !important;
  color: #999 !important;
  opacity: 1;

  &:hover {
    opacity: 1;
    border: 1px solid #999;
  }
}

input[type=checkbox] + label.disabled::before {
  background-color: #d8dadc;
  color: #999;
  opacity: .5;
  border-color: #999;
}

.x-btn-primary {
  margin-right: 10px;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
