.form-card {
  box-shadow: 1px 1px 5px var(--primary-color);
  border-radius: 12px;
  text-align: center;
  margin-bottom: 50px;
  color: white;
  .form-card-header {
    border-radius: 12px 12px 0 0;
    padding: 25px;
    width: 50rem;
    .form-card-title {
      font-size: 2rem;
      font-weight: bold;
    }
    .form-card-subtitle {
      font-size: 1.25rem;
    }
  }
  .form-card-body {
    padding: 25px;
    width: 50rem;
    border-radius: 0 0 12px 12px;
    color: black;
    .form-card-body-wrapper {
      width:400px;
      margin: 0 auto;
    }
    input, select {
      padding: 5px;
      border-radius:4px;
      width: 200px;
    }
    .truncated {
      width: 200px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .credential-row {
      margin: 10px auto;
      display: flex;
      align-items: center;
      font-size: 16px;
      .credential-label {
        margin-right:20px;
        width: 150px;
        text-align:left;
        font-weight: bold;
        font-size: 16px;
      }
    }
    .link {
      font-size: 14px;
      cursor: pointer;
      color: var(--link-color);
      font-weight: 200;
      left: 5px;
      position: relative;
      &:hover {
        text-decoration: underline;
      }
    }
    .change-password-button {
      display: block;
      text-align: right;
      margin-right: 35px;
    }
  }
}
