/*** Color ***/

$primary-color: var(--primary-color);
$secondary-color: var(--secondary-color);
$primary-lighter-color: #daffda;
$primary-darker-color: var(--primary-darker-color);

$background-color: #f2f0f9;
$warn-lighter-color: #fd6297;
$alert-success-color: #1ea633;
$alert-failure-color: #d32f2f;
$background-grey: #f2f2f2;
$border-grey: #d9d9d9;
$button-color-text: $primary-darker-color;

// $warn-lighter-color: var(--warn-lighter-color);
// $primary-lighter-color: var(--primary-lighter-color);

/*** Text Color ***/

$text-light: #717171;

/***************   */

/*** Sidenav ***/

$sidenav-dock-width: 60px;
$sidenav-width: 400px;

/*** Button ***/

$button-hover: #fcfaff;

/*** Font ***/

$font: var(--font);

/*** Link ***/
$link-color: var(--link-color);
